@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@200;300;400;500&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  overflow: hidden;
}

.mont{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.popp{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.popp__project{
  font-family: 'Poppins', sans-serif;
  font-size: 26px;
  font-style: bold;
  font-weight: 700 !important;
  max-width: 973px;
  margin: auto;
  margin-bottom: 20px;
}

.poppins-subtitle{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.main-content{
  padding-bottom: 20px;
}

.bridge-23-head{
  font-size: 26px;
  margin-top: -2px;
}

span{
  display: inline-block;
}

.image-section{
  height: 400px;
  width: 100%;
  /* background-image: url("./images/background.png"); */
  background-image:
  linear-gradient(to bottom, rgba(115, 142, 172, 0.5), #2A658E),
  url('./images/background.png');
  background-size: cover;
  background-position-y: bottom;
  position: relative;
  z-index: -1;
}

.content-top{
  color: white;
}

.about-bridge23{
  position: absolute;
  top: 13%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 80%; 
  display: flex;
  max-width: 973px;
}

.about-bridge23 > div > button{
  background-color: white;
  outline: none;
  border: none;
  border-radius: 20%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  width: 55px;
  height: 49px;
  margin-right: 14px;
}

.circle-collection-wrap{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: end;
}

.circle-collection-wrap > img{
  margin-top: 30px;
  border-radius: 10px;
  width: 90%;
}

.circle-collection{
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: end;
}

.circle{
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
}

.circle-clr{
  width: 30px;
  height: 30px;
  background-color: #D9D9D9;
  border-radius: 50%;
  position: relative;
}

.circle-clr > *{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.top-bar{
  width: 48%; 
  height: 60px;
  background-color: white;
  margin: auto;
  margin-top: -30px;
  z-index: 1;
  border-radius: 8px 8px 0px 0px;
  color: #153B5E;
  display: flex;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
}

.top-bar-left{
  display: flex;
  margin-top:5px;
  gap: 60px;
  flex:4;
}

.top-bar-right{
  display: flex;
  flex:1;
}

.top-bar-right > button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500px;
  background-color: white;
  border: 1px solid #153B5E;
  /* outline: blue; */
  padding: 2px 10px;
  border-radius: 4px;
  min-width: 130px;
}

.top-bar-right > button:hover {
  cursor: pointer;
}

.about-icon-wrap{
  background-color: #E3EFF3;
  width: 100%;
  min-height: 200px;
  margin-top: -30px;
  padding: 30px 30px;
  position: relative;
  text-align: center;
}

.about-icon-wrap > p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #153B5E;
}

.about-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap:60px;
  font-family: poppins;
  color: #153B5E;
  flex-wrap: wrap;
}

/* .about-icon img{
  flex-direction: row;
  flex-wrap: wrap;
} */

.logos-partners:hover{
  cursor: pointer;
}

.active-projects{
  margin: 40px auto;
  width: 80%;
}

.active-projects > span {
  font-weight: 500;
  color: #153B5E;
  margin-bottom: 20px;
}

.card{
  background-color: #f4f1f18c;
  padding: 40px;
  color: #434343;
  font-family: poppins;
  font-size: 14px;
  max-width: 973px;
  margin: auto;
  margin-bottom: 60px;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.project-heading {
  font-family: poppins;
  font-size: 20px;
  font-weight: 600;
  display: flex;
    align-items: end;
    gap: 12px;
}

.head-div{
  display: flex;
  flex-direction: column;
  flex: 1.2;
}

.head-inner-div{
  display: flex;
  align-items: center;
  gap: 16px;
}

.sub-heading{
  font-style: italic;
  font-size: 13px;
}

.visit-website{
  color: #2A658E;
  font-size: 16px;
  font-weight: 500;
}

.head-inner-div img{
  height: 20px;
  width: 30px;
}

.card > div > div > span > span {
  margin-top:-10px;
}

.card > div {
  display: flex;
}

.project-description{
  line-height: 31px;
  text-align: justify;
  flex: 1;
  gap: 20px;
}


.project-heading:nth-child(1){
flex: 2;
}

.project-heading:nth-child(2){
  flex: 2;
  }

.project-heading> div:nth-child(5) > div > span:nth-child(1){
  font-weight: 500;
}
  
.project-heading> div:nth-child(5) > div > span:nth-child(3){
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;    
}


.footer{
  width: 80%;
  margin: 40px auto;
  color: #153B5E;
  font-family: 'Poppins', sans-serif;
}

@media screen and (min-width: 2800px){
  .image-section{
    height: 50vh;
  }
}

@media screen and (max-width: 700px){
  .image-section{
    height: 520px;
  }

  .about-bridge23{
    top: 7%;
  }
}

@media screen and (max-width: 400px){
  .about-bridge23{
    top: 4%;
  }
}

@media screen and (min-width: 1800px){
  .image-section{
    height: 60vh;
  }
}

@media screen and (max-width: 1200px){

  .about-bridge23{
    width: 80%;
  }

  .top-bar{
    width: 80%;
  }
}

@media screen and (max-width: 1025px){
  .card > div {
    flex-direction: column;
  }
}

@media screen and (max-width: 481px){
  .card{
  padding: 15px;
  }

  .top-bar{
    width: 100%;
  }

  .top-bar-left{
    gap: 10px;
  }

}

@media screen and (max-width: 380px){
  .about-bridge23 {
    top: 5%;
  }
}