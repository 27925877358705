.bridge23-logo{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 30px;
}

.bridge23-title{
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 10px
}